<template>
  <span>
    <v-img
        height="100vh"
        src="./assets/home-background.jpg"
        lazy-src="./assets/home-background-sm.jpg"
        class="white--text backgroundImage"
        gradient="to top, rgba(13, 3, 29, .85), rgba(25, 25, 38, .85)"
    >
      <v-container fill-height
      >
        <v-row
            align="center"
            class="pt-5 px-3"
        >
          <v-sheet
              color="transparent"
              dark
              max-width="500"
          >
            <h1
                class="mb-4"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
            >
              China Town<br>
            </h1>

            <div class="py-1" />

            <v-row
                class="ma-0"
                justify="start"
            >
              <v-btn
                  class="text--primary my-2"
                  color="white"
                  large
                  min-width="175"
                  rounded
                  :to="{name:'menu'}">
                Jetzt bestellen!
              </v-btn>
            </v-row>
          </v-sheet>
        </v-row>
      </v-container>
    </v-img>
    <section
        id="infos"
        class="grey lighten-2 px-3 pt-10"
    >
             <p
                 class="text-center mx-auto title"
             >
           Öffnungszeiten
             </p>

          <v-divider style="max-width: 60px" class="mt-5 mb-4 mx-auto" />


      <v-container>
        <v-row align-content="center" align="center" justify="center">
          <opening-hour-display class="pb-12"></opening-hour-display>
        </v-row>
      </v-container>
    </section>
  </span>


</template>

<script>
import { mdiMenuLeft, mdiMenuRight} from '@mdi/js'
import OpeningHourDisplay from "@/components/OpeningHourDisplay";

export default {
name: "home",
  components: {OpeningHourDisplay},
  data(){
    return{
      mdiMenuLeft:mdiMenuLeft,
      mdiMenuRight:mdiMenuRight
    }
  },
}
</script>

<style lang="sass">
</style>
