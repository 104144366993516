import home from './home'
const restaurantName = "chinatown"
const firebaseTarget = "chinatown"
const restaurantId = "GQ4uC16Z8yYKcmX7HeaI"
const restaurantString = 'China Town'
const restaurantSubString = 'Oeserstraße 219'
const showCouponSettings = true
const menuAccentColor = 'green'
const logoInAppBar = false

const vuetifyTheme = {
    primary: '#CB9173',
    secondary: '#a491d3',
    accent: '#818aa3',
    error: '#f9dad0',
    background: '#fff',
    cards: '#fff'
}

const appBarColor = 'rgba(13, 3, 29, .95)'


const extraCategoryPics = []

const appBarDarkMode = true

export {restaurantName, home, firebaseTarget, appBarColor, restaurantId, restaurantString, showCouponSettings, restaurantSubString, menuAccentColor, vuetifyTheme, logoInAppBar, extraCategoryPics, appBarDarkMode}
